import { Link } from "gatsby";
import React, { useMemo, useState } from "react";
import theme from "../theme";
import { HighlightText, Text } from "./Text";
import Speaker from "./svgs/Speaker";
import IconButton from "./IconButton";

const names = {
  en: {
    lang: "th-TH",
    lastname: "Ng",
    firstname: "Frances",
    order: ["firstname", "lastname"],
  },
  th: {
    lang: "th-TH",
    lastname: "งู",
    firstname: "ฟรานเซส",
    order: ["firstname", "lastname"],
  },
  ja: {
    lang: "ja-JP",
    lastname: "イング",
    firstname: "フランシス",
    order: ["firstname", "lastname"],
  },
  //   zh: {
  //     lang: "zh-HK",
  //     lastname: "吳",
  //     firstname: "宛霖",
  //     order: ["lastname", "firstname"],
  //   },
};
const NameLogo = () => {
  const [lang, setLang] = useState("en");
  const play = React.useCallback(() => {
    speechSynthesis.cancel();
    const voices = speechSynthesis.getVoices();
    let utterance = new SpeechSynthesisUtterance(
      names[lang].order.map((p) => names[lang][p]).join(" ")
    );
    utterance.voice = voices.find((p) => p.lang === names[lang].lang);
    speechSynthesis.speak(utterance);
  }, [lang]);

  const firstname = useMemo(() => {
    return <span key="firstname">{names[lang].firstname} </span>;
  }, [lang]);

  const lastname = useMemo(() => {
    return (
      <HighlightText
        as="span"
        key="lastname"
        color={theme.colors.navyBlue}
        $highlightColor={theme.palette.bright.brightGreen}
        $highlightHeight="10px"
        ml={-2}
        px={1}
      >
        {names[lang].lastname}
      </HighlightText>
    );
  }, [lang]);
  return (
    <>
      <Link to="/" style={{ display: "inline-block", textDecoration: "none" }}>
        <Text
          variant="h4"
          $highlight
          $highlightColor={theme.colors.orange}
          color={theme.palette.bright.red}
          $highlightOnHover
          $highlightHeight="5px"
          $autoTextColor={false}
        >
          {names[lang].order.map((key) => {
            if (key === "firstname") return firstname;
            if (key === "lastname") return lastname;
            return null;
          })}
        </Text>
      </Link>
      <IconButton onClick={play}>
        <Speaker />
      </IconButton>
      {/* 
      <select
        onChange={(e) => {
          setLang(e.target.value);
        }}
      >
        {keys(names).map((l) => (
          <option key={l} value={l}>
            {l}
          </option>
          //   <Box key={l} onClick={() => setLang()} >TH</Box>
        ))}
      </select> */}
    </>
  );
};
export default NameLogo;
