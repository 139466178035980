import styled from "styled-components";
import { color, flex, textAlign } from "styled-system";

export default styled.button`
  border: 0;
  background: none;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
  ${textAlign}
  ${flex}
  ${color}
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    opacity: 0.5;
  }
  &:where(:active) {
    transform: translateY(1px);
  }
  font-family: inherit;
  font-size: inherit;
`;
