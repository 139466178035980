import React, { useCallback, useMemo } from "react";
import { some } from "lodash";
import { navigate } from "gatsby";

const SiteContext = React.createContext();

export const listFormats = [
  {
    label: "List",
    key: "text",
  },
  {
    label: "Grid",
    key: "graphical",
  },
];
export const contentListingType = {
  YEAR: "date",
  CHAPTERS: "chapters",
  TYPE: "type",
};

export const listOrders = [
  contentListingType.CHAPTERS,
  contentListingType.TYPE,
  contentListingType.YEAR,
];

export const SiteProvider = ({ children }) => {
  const [listOrder, setListOrder] = React.useState(contentListingType.TYPE);
  const [listFormat, setListFormat] = React.useState("text");
  const [activeFilters, setActiveFilters] = React.useState([]);
  const [showTagsPanel, setShowTagsPanel] = React.useState(false);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const ref = React.useRef(null);
  const scrollToTop = React.useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);
  const scrollToAnchor = React.useCallback((id) => {
    const { y } = document.getElementById(id)?.getBoundingClientRect() || {};
    window.scrollTo({ top: y + window.scrollY - 100, behavior: "smooth" });
  }, []);

  const updateFilter = React.useCallback(
    (tag) => {
      setActiveFilters((prev) =>
        some(prev, (p) => p.name === tag.name)
          ? prev.filter((p) => p.name !== tag.name)
          : [...prev, tag]
      );
      setShowTagsPanel(true);
      scrollToTop();
    },
    [setActiveFilters, scrollToTop]
  );

  const selectFilterFromPage = React.useCallback(
    (tag) => {
      updateFilter(tag);
      navigate("/content");
    },
    [updateFilter]
  );
  const updateOrderFromParams = useCallback(
    (params) => {
      const order = params.get("order");
      if (listOrders.includes(order)) {
        setListOrder(order);
        const type = params.get("type");
        if (type) {
          setTimeout(() => {
            scrollToAnchor(`${order}-${type}`);
          }, 100);
        }
      }
    },
    [scrollToAnchor]
  );

  const value = useMemo(
    () => ({
      ref,
      scrollPosition,
      setScrollPosition,
      scrollToTop,
      scrollToAnchor,
      setListOrder,
      listConfig: {
        order: listOrder,
        format: listFormat,
      },
      setListFormat,
      containerRef: ref,
      activeFilters,
      setActiveFilters,
      updateFilter,
      showTagsPanel,
      setShowTagsPanel,
      selectFilterFromPage,
      updateOrderFromParams,
    }),
    [
      scrollPosition,
      scrollToTop,
      scrollToAnchor,
      listOrder,
      listFormat,
      activeFilters,
      updateFilter,
      showTagsPanel,
      selectFilterFromPage,
      updateOrderFromParams,
    ]
  );

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export const useSiteConfig = () => React.useContext(SiteContext);
