// https://huemint.com/illustration-3/#palette=f7fdfc-07034e-4e2563-6c72cc-ff6b4a-fcc149-53a5c0-d8a11e-84a453-f2dbb0-54c73e
const theme = {
  colors: {
    black: "#000e1a",
    white: "#fff",
    grey: "#666666",
    lightGrey: "#ececec",
    blue: "#007ce0",
    navy: "#004175",
    yellow: "#f3cf25",
    orange: "#fdc935",
    darkOrange: "#fbb52a",
    green: "#71d753",
    darkBlue: "#171541",
    navyBlue: "#002e98",
    red: "#eb322f",
    purple: "#d399ba",
    greyGreen: "#7a909e",
    paleBlue: "#96aade",
    brightGreen: "#6ab961",
  },
  palette: {
    bright: {
      purple: "#4e2563",
      darkBlue: "#07034e",
      navy: "#004175",
      palePurple: "#6c72cc",
      red: "#ff6b4a",
      orange: "#fcc149",
      paleBlue: "#53a5c0",
      brown: "#d8a11e",
      green: "#84a453",
      beige: "#f2dbb0",
      brightGreen: "#54c73e",
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 18, 24, 32, 38, 44],
  fonts: {
    sansSerif: "calling-code, sans-serif",
    serif: "adobe-caslon-pro, serif",
  },
  breakpoints: ["40em", "52em", "64em", "80em"],
  typography: {},
};

theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

theme.fontSizes.caption = theme.fontSizes[0];
theme.fontSizes.subtitle = theme.fontSizes[1];
theme.fontSizes.body = theme.fontSizes[2];
theme.fontSizes.h4 = theme.fontSizes[3];
theme.fontSizes.h3 = theme.fontSizes[4];
theme.fontSizes.h2 = theme.fontSizes[5];
theme.fontSizes.h1 = theme.fontSizes[6];

theme.typography.h1 = {
  fontSize: [theme.fontSizes[5], theme.fontSizes[6]],
};
theme.typography.h2 = {
  fontSize: [theme.fontSizes[4], theme.fontSizes[5]],
};
theme.typography.h3 = {
  fontSize: [theme.fontSizes[3], theme.fontSizes[4]],
};
theme.typography.h4 = {
  fontSize: [theme.fontSizes[2], theme.fontSizes[3]],
};
theme.typography.body = {
  fontSize: [14, theme.fontSizes[2]],
};
theme.typography.caption = {
  fontSize: [10, theme.fontSizes[0]],
};
theme.typography.subtitle = {
  fontSize: [theme.fontSizes[0], theme.fontSizes[1]],
};
export default theme;
