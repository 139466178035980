module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/notion-plugin/gatsby-browser.js'),
      options: {"plugins":[],"notionKey":"secret_z3kJaIbmlOG7dvRmiaaewxUS2VqFY8gaFT3cHWzGzrp","cloudName":"sonders","apiKey":"495245859298978","apiSecret":"5sze1OtsXSuxVXBdLfE0iSdGgFc","resourceType":"image","prefix":"franxyz/","context":true,"tags":true,"cloudinaryNameSpace":"464dde5f-8744-4a8b-b316-edb9ea52cf9a","databases":{"book":"12d0da9a78894c40843b338a6ae5bbc8","blog":"17270cc36f964e4b8d9ef745cb42711c","collection":"bcf293e64c7d4857bd9a8a63c66addf2","content":"4d326bb8f51c4d80bea42d8600ee6526"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Frances Ng","description":"Frances Ng - an Engineer","lang":"en","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f96bd31386ba807e65fa393abaef9950"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-77899441-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
