import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { display, flexbox } from "styled-system";
import { Text } from "./Text";

const NavLink = styled(Text)`
  padding: 0;
  ${display}
  ${flexbox}
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.black};
  ${({ $isActive, theme }) =>
    $isActive &&
    `
  color: ${theme.colors.blue};
  `}
  &:disabled {
    color: ${({ theme }) => theme.colors.grey};
  }
  ${({ $textDecoration }) =>
    $textDecoration === "hover" &&
    `
    transition: 0.1s ease-in;
  text-decoration:none;
    &:hover {
      text-decoration: underline;
    }
  `}
`;
NavLink.defaultProps = { as: "a" };

const NavigationLink = ({
  to,
  children,
  isActive,
  textDecoration,
  disabled,
  ellipsisWidth,
  ...props
}) => (
  <NavLink
    as={disabled ? null : Link}
    to={disabled ? null : to}
    $isActive={isActive}
    $ellipsisWidth={ellipsisWidth}
    disabled={disabled}
    $textDecoration={textDecoration}
    {...props}
  >
    {children}
  </NavLink>
);
export default NavigationLink;
