import Box from "./Box";
import React from "react";
import theme from "../theme";

export default React.forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      // width={["100vw", "100vw", "100%"]}
      maxWidth={[null, null, theme.breakpoints.lg]}
      m="0 auto"
      position="relative"
      {...props}
    />
  );
});
