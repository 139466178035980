import React from "react";

const Speaker = ({ width = 24, height = 24, color = "#000" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0848 16.9323C20.5298 12.6556 20.5298 8.34432 20.0848 4.06762C19.9261 2.54257 18.1678 1.77006 16.9372 2.68478L12.8336 5.73519C11.5831 6.6647 10.0665 7.16665 8.5084 7.16665H4.6792C3.98884 7.16665 3.4292 7.72629 3.4292 8.41664V12.5833C3.4292 13.2737 3.98884 13.8333 4.6792 13.8333H5.30348L4.45812 16.9882C4.36392 17.3398 4.53573 17.7082 4.86559 17.8621L8.54674 19.5787C8.74766 19.6724 8.97974 19.6724 9.18067 19.5787C9.3816 19.485 9.53078 19.3072 9.58815 19.093L10.8508 14.3806C10.8643 14.3306 10.8723 14.2803 10.8753 14.2305C11.5727 14.4714 12.2338 14.8189 12.8336 15.2648L16.9372 18.3152C18.1677 19.2299 19.9261 18.4574 20.0848 16.9323ZM18.5928 4.22285C19.0271 8.39634 19.0271 12.6036 18.5928 16.7771C18.5545 17.1457 18.1295 17.3324 17.8321 17.1114L13.7284 14.0609C12.2193 12.9391 10.3888 12.3333 8.50839 12.3333L4.9292 12.3333L4.9292 8.66665H8.5084C10.3888 8.66665 12.2193 8.06085 13.7284 6.93902L17.8321 3.88861C18.1295 3.66752 18.5545 3.85424 18.5928 4.22285ZM9.43615 13.8929C9.12975 13.8534 8.8199 13.8333 8.50839 13.8333H6.85639L6.06989 16.7686L8.3706 17.8415L9.40196 13.9924C9.41118 13.958 9.42264 13.9248 9.43615 13.8929Z"
        fill={color}
      />
    </svg>
  );
};
export default Speaker;
