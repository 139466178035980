import React, { useMemo } from "react";

const PageContext = React.createContext();

export const PageProvider = ({ children, ...props }) => {
  const value = useMemo(() => props, [props]);

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => React.useContext(PageContext);
