exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-lab-qwerty-keyboard-js": () => import("./../../../src/pages/lab/qwerty-keyboard.js" /* webpackChunkName: "component---src-pages-lab-qwerty-keyboard-js" */),
  "component---src-templates-appendix-index-js": () => import("./../../../src/templates/appendix/index.js" /* webpackChunkName: "component---src-templates-appendix-index-js" */),
  "component---src-templates-appendix-system-js": () => import("./../../../src/templates/appendix/system.js" /* webpackChunkName: "component---src-templates-appendix-system-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-introduction-js": () => import("./../../../src/templates/introduction.js" /* webpackChunkName: "component---src-templates-introduction-js" */),
  "component---src-templates-listing-js": () => import("./../../../src/templates/listing.js" /* webpackChunkName: "component---src-templates-listing-js" */),
  "component---src-templates-notion-blog-js": () => import("./../../../src/templates/notion-blog.js" /* webpackChunkName: "component---src-templates-notion-blog-js" */),
  "component---src-templates-notion-chapter-js": () => import("./../../../src/templates/notion-chapter.js" /* webpackChunkName: "component---src-templates-notion-chapter-js" */),
  "slice---src-components-slice-footer-js": () => import("./../../../src/components/slice/Footer.js" /* webpackChunkName: "slice---src-components-slice-footer-js" */)
}

