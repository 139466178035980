import React from "react";
import Box from "../components/Box";
import Container from "../components/Container";
import NavigationLink from "../components/NavigationLink";
import Transition from "./Transition";
import { useSiteConfig } from "../context";
import { Text } from "../components/Text";
import NameLogo from "../components/NameLogo";
import { PageProvider } from "../pageContext";
import { Slice } from "gatsby";

const routes = [
  {
    to: "/",
    disabledPath: "/",
    label: "cover",
    display: ["none", "inline-block"],
  },
  {
    to: "/introduction",
    disabledPath: "/introduction/",
    label: "intro",
    activeMatch: new RegExp("/introduction"),
  },
  {
    to: "/content",
    disabledPath: "/content/",
    label: "content",
    activeMatch: new RegExp("/content|/blog|/book|/chapter"),
  },
  {
    to: "/appendix",
    disabledPath: "/appendix/",
    label: "appendix",
    activeMatch: new RegExp("/appendix"),
  },
];
const Content = (props) => {
  const { ref, scrollToTop } = useSiteConfig();

  React.useEffect(() => {
    scrollToTop();
  }, [props.location]);

  return (
    <PageProvider {...props}>
      <Box ref={ref}>
        <Box position="sticky" top={0} zIndex={10} bg="#fff">
          <Container
            pt={[3, 3, 3, 4]}
            pb={[2, 2]}
            px={[3, 3, 2, 0]}
            style={{
              zIndex: 10,
              position: "relative",
            }}
            display="flex"
          >
            <Box flex={1} alignSelf="flex-end" minWidth={["30vw", "0"]}>
              <NameLogo />
              <Text variant="subtitle">Frances with an "e"</Text>
            </Box>
            <Box
              mt={[2, 0]}
              display="flex"
              gridGap={["4px 16px", 4]}
              alignItems="center"
              flexWrap="wrap"
              justifyContent="end"
            >
              {routes.map(
                ({ to, disabledPath, label, activeMatch, display }) => {
                  const disabled = props.location.pathname === disabledPath;
                  return (
                    <NavigationLink
                      key={to}
                      to={to}
                      disabled={disabled}
                      display={display}
                      isActive={
                        to === "/"
                          ? props.location.pathname === "/"
                          : activeMatch?.test(props.location.pathname)
                      }
                    >
                      {label}
                    </NavigationLink>
                  );
                }
              )}
            </Box>
          </Container>
        </Box>

        <Transition location={props.location}>
          <Container
            pb={5}
            px={[0, 2, 2, 0]}
            overflowX={["hidden", "initial"]}
            pt={[1, 4]}
          >
            {props.children}
          </Container>
        </Transition>
      </Box>
      <Slice alias="footer" />
    </PageProvider>
  );
};

export default Content;
