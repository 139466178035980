import React from "react";
import {
  fontWeight,
  space,
  fontSize,
  lineHeight,
  color,
  typography,
  layout,
  flexbox,
} from "styled-system";
import styled from "styled-components";
import { readableColor } from "polished";

export const withHighlight = ({
  $highlight = false,
  $highlightColor,
  $highlightOnHover,
  theme,
  $highlightHeight = "0.5em",
  $highlightWidth = "100%",
  color,
  $autoTextColor = false,
  $highlightOffset = "0",
}) => {
  if ($highlight || $highlightOnHover) {
    const highlightBg = $highlightColor
      ? theme.colors[$highlightColor] || $highlightColor
      : theme.colors.orange;
    const highlightTextColor = readableColor(highlightBg);
    let textColor =
      color ||
      ($highlightOnHover && highlightTextColor === "#fff"
        ? theme.colors.black
        : highlightTextColor);
    const textShadowColor =
      theme.colors[$highlightColor] || $highlightColor || theme.colors.black;

    if (textColor === highlightBg) {
      textColor = theme.colors.black;
    }
    return `
        background: linear-gradient(0deg, ${highlightBg}, ${highlightBg}) no-repeat;
        background-position: right 0px bottom ${$highlightOffset};
        background-size: ${
          $highlightOnHover ? "0%" : $highlightWidth
        } ${$highlightHeight};
        transition: background-size 250ms;

        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        color: ${textColor};
        ${
          textColor === "#fff" && $autoTextColor
            ? `
        text-shadow: -1px 0 ${textShadowColor},
           0 1px ${textShadowColor}, 1px 0 ${textShadowColor},
           0 -1px ${textShadowColor};
        `
            : ``
        }
     &:where(:hover, :focus-visible) {
            background-size: ${$highlightWidth} ${$highlightHeight};
            background-position-x: left;
            ${
              $autoTextColor
                ? `
            color: ${highlightTextColor};
            ${
              highlightTextColor === "#fff"
                ? `
            text-shadow: -1px 0 ${textShadowColor},
            0 1px ${textShadowColor}, 1px 0 ${textShadowColor},
            0 -1px ${textShadowColor}; 
            `
                : ``
            }
            `
                : ``
            }
          }
        `;
  }
  return ``;
};

export const getFontFamily = ({ $serif, theme, fontFamily }) =>
  `font-family: ${
    fontFamily === "inherit"
      ? "inherit"
      : $serif
      ? theme.fonts.serif
      : theme.fonts.sansSerif
  };`;

export const Text = styled.div`
  ${space}
  ${fontSize}
    ${fontWeight}
    ${lineHeight}
    ${color}
    ${layout}
    ${flexbox}
    ${typography}
    ${getFontFamily}
    ${({ $serif, ...props }) => {
      if ($serif) {
        return `
      margin-block-start: 0.2em;
      margin-block-end: 0em;
      line-height: 1em;
      letter-spacing: 1px;
      `;
      }
      return ``;
    }}

  ${withHighlight}
  ${({ fontSize }) =>
    fontSize === "inherit"
      ? `
    font-size: inherit;
  `
      : ``}
  ${({ theme, variant = "body" }) =>
    `
    ${
      theme.typography[variant]?.fontSize?.[0] &&
      `
    font-size: ${theme.typography[variant]?.fontSize?.[0]}px;
    `
    }
    ${theme.breakpoints
      .map((bp, idx) =>
        theme.typography[variant]?.fontSize?.[idx]
          ? `
  @media screen and (min-width: ${bp}) {
    font-size: ${theme.typography[variant]?.fontSize?.[idx]}px;
  }
  `
          : ``
      )
      .join("")}
  `}
    }
  }}

`;
export const HighlightText = styled(Text)`
  font-size: inherit;
  font-family: ${({ $serif, theme }) =>
    $serif ? theme.fonts.serif : "inherit"};
`;
HighlightText.defaultProps = {
  display: "inline-block",
  $highlight: true,
};

Text.defaultProps = {};

export const Heading = React.forwardRef((props, ref) => {
  const as = React.useMemo(() => {
    if (props.variant === "inherit") return null;
    if (props.as) return props.as;
    if (props.variant && ["h1", "h2", "h3"].includes(props.variant))
      return props.variant;
    return null;
  }, [props]);

  return <Text {...props} as={as} ref={ref} />;
});
