import {
  fontWeight,
  space,
  fontSize,
  lineHeight,
  color,
  typography,
  layout,
  grid,
  flexbox,
  position,
  fontFamily,
  borderRadius,
} from "styled-system";
import styled from "styled-components";

const Box = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  ${space}
  ${fontSize}
      ${fontWeight}
      ${lineHeight}
      ${color}
      ${typography}
      ${layout}
      ${grid}
      ${flexbox}
      ${position}
      ${fontFamily}
      ${borderRadius}
`;

export default Box;
